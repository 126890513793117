import {
  getCdnImageSrc,
  VStack,
  Image,
  Spacing,
  HStack,
  colors,
} from "goi_common";
import Script from "next/script";

import { HomeMenuCardSection } from "./components/HomeMenuCardSection";
import RenewalFooter from "@/components/Layout/RenewalFooter";
import { MobileLayout } from "@/components/Layout";

export const HomeContainer = () => {
  return (
    <MobileLayout>
      <VStack
        className="home-container-background"
        css={{
          width: "100%",
          backgroundImage: `
              linear-gradient(180deg, #3A2E2B99 75%, #FFF 100%),
              url(${getCdnImageSrc("bugo/bugo_main_flower.jpg")})
            `,
          backgroundPosition: "top",
          backgroundSize: "cover",
          paddingBottom: "85px",
        }}
      >
        <HomeHeroTitle />
        <HomeMenuCardSection />
      </VStack>
      <HomeIntroduceSection />
      <Spacing gap={50} />

      <RenewalFooter />

      <Script
        src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.2/kakao.min.js"
        integrity="sha384-TiCUE00h649CAMonG018J2ujOgDKW/kVWlChEuu4jK2vxfAAD0eZxzCKakxg55G4"
        crossOrigin="anonymous"
        onLoad={() => console.log("✅start✅")}
      />
    </MobileLayout>
  );
};

const HomeHeroTitle = () => {
  return (
    <VStack
      className="head_24_b"
      gap="4px"
      css={{
        padding: "48px 20px 20px 20px",
      }}
    >
      <h1
        className="white"
        css={{
          whiteSpace: "pre-wrap",
          textShadow: "0px 0px 4px rgba(0, 0, 0, 0.24)",
          margin: 0,
        }}
      >
        {"가장 편리한\n모바일 부고 서비스"}
      </h1>
      <p
        className="body_18_m white"
        css={{
          margin: 0,
        }}
      >
        미리 준비하고 평온하게 전하세요.
      </p>
    </VStack>
  );
};

const HomeIntroduceSection = () => {
  return (
    <VStack padding="60px 0px 20px 0px">
      <HomeIntroduceBox
        title="언제든 작성"
        description={"미리 만들어 두었다가\n임종 시에 바로 전송할 수 있어요."}
        src={getCdnImageSrc("bugo/home/intro_0.jpg")}
        alt={"이미지넣어야해요"}
      />
      <Spacing gap={40} />
      <HomeIntroduceBox
        title="조문객 명단 관리 기능"
        description={"부고를 받게 될 조문객 명단을\n미리 등록할 수 있어요."}
        src={getCdnImageSrc("bugo/home/intro_1.jpg")}
        padding="0px 20px"
        alt={"이미지넣어야해요"}
      />
      <Spacing gap={80} />

      <HomeIntroduceBox
        title="손쉽고 빠른"
        description={"누구나 쉽게 작성할 수 있어요."}
        width="119px"
        height="137px"
        margin="40px 0px 0px 0px"
        src={getCdnImageSrc("bugo/home/intro_2.jpg")}
        alt={"이미지넣어야해요"}
      />
      <Spacing gap={80} />

      <HomeIntroduceBox
        title="통합 관리"
        description={
          "받은 근조화환, 따로 관리하지 마세요.\n애도를 표해주신 분들 목록을\n 따로 알려드려요."
        }
        src={getCdnImageSrc("bugo/home/intro_3.jpg")}
        alt={"이미지넣어야해요"}
      />
      <Spacing gap={40} />
    </VStack>
  );
};

const HomeIntroduceBox = ({
  title,
  description,
  src,
  alt,
  width,
  height,
  margin,
  padding,
}: {
  title: string;
  description: string;
  src: string;
  alt: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
}) => {
  return (
    <VStack gap="8px">
      <VStack gap="4px" alignItems="center">
        <h3
          className="subtitle_14_b"
          css={{
            color: colors.warmGray650,
          }}
        >
          {title}
        </h3>
        <p
          className="subtitle_18_sb"
          css={{
            whiteSpace: "pre-wrap",
            textAlign: "center",
            margin: 0,
          }}
        >
          {description}
        </p>
      </VStack>
      <HStack justifyContent="center" alignItems="center">
        <Image
          src={src}
          css={{
            width: width ?? "100%",
            height: height ?? "100%",
            margin: margin ?? "0px",
            padding: padding ?? "0px",
          }}
          alt={alt}
        />
      </HStack>
    </VStack>
  );
};
